export const resources = {
	users: "users",
	locations: "locations",
	regions: "regions",
	towns: "towns",
	books: "books",
	booksgroups: "booksgroups",
	collections: "collections",
	publishings: "publishings",
	stocks: "stocks",
	authors: "authors",
};
